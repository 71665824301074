<template>
  <div class="u-display-flex entity-details-table-wrapper u-width-100">
    <dashboard-inflator
      :page="pageInfo"
      class="u-width-100"
      :request-params="requestParams"
      :page-context="context"
      :widget-level-settings="headerOptions"
      :show-header="false"
      :disable-envelop-width-class="true"
      @widgetParamsCollectionUpdated="collectionUpdated"
      @widgetParamsChanged="widgetParamsChanged"
      @metadataUpdated="metadataUpdated"
    />
  </div>
</template>
<script>
// wrapper service for table component using dashboard inflator

import dashboardMixin from '@/components/widgets/custom_widgets/dashboard_mixin.js';
import dashboardServiceTable from '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue';
import {
  updateExistingWidgetParams,
  saveExistingWidgetParams
} from '@/pages/entity_details/common_components/helper.js';
import Vue from 'vue';

export default {
  mixins: [dashboardMixin],
  props: {
    widget: {
      default: () => {},
      type: Object
    },
    dateFilter: {
      default: () => {},
      type: Object
    },
    tabMetadata: {
      default: () => {},
      type: Object
    },
    headerOptions: {
      default: () => {},
      type: Object
    },
    entityConfig: {
      default: () => {},
      type: Object
    },
    context: {
      type: Object,
      default: () => {
        return this;
      }
    },
    tableDataComponentKey: {
      type: Number,
      default: 1
    }
  },
  data() {
    const pageInfo = {
      name: this.tabMetadata.page
    };
    return {
      pageInfo,
      requestParams: null,
      widgetMetadata: null,
      initRequestParams: {
        global: {
          ...pageInfo,
          ':page': pageInfo.name,
          ':entityType': '#ALLOVER_AGGREGATE',
          key: this.tableDataComponentKey
        }
      }
    };
  },
  watch: {
    dateFilter: {
      handler(newVal) {
        this.initRequestParams.global = {
          ...this.initRequestParams.global,
          ...newVal
        };
        this.updateEntireRequestParams(this.initRequestParams);
      },
      immediate: true,
      deep: true
    },
    tableDataComponentKey: {
      handler(newVal) {
        const globalRequestParams = this.getGlobalRequestParams();
        this.updateGlobalRequestParams({ ...globalRequestParams, key: newVal });
      }
    }
  },
  created() {
    this.setFooterOptions();
    updateExistingWidgetParams(
      'campaignCreationState',
      this.widget,
      this.$route?.params?.entityId
    );
    this.initRequestParams[this.widget[':widget']] = this.widget;
    Vue.component(this.widget[':widget'], dashboardServiceTable);
  },
  methods: {
    widgetParamsChanged(value) {
      this.$emit('widgetParamsChanged', value);
    },
    collectionUpdated(value) {
      saveExistingWidgetParams(
        'campaignCreationState',
        value,
        this.$route?.params?.entityId
      );
    },
    setFooterOptions() {
      let footerOptions = [];
      let showLastUpdatedDate = true;
      if (this.entityConfig?.maxFeedDate) {
        const maxDate =
          this.$store?.getters?.getMaxDate?.ams_campaign?.max_feed_date;
        showLastUpdatedDate = false;
        footerOptions = [
          {
            ui_component: 'iconText',
            props: {
              text: `Last Updated: ${
                Vue.options.filters.formattable_date(maxDate) || 'NA'
              }`,
              textClasses: 'u-color-grey-x-light u-font-size-7'
            }
          }
        ];
      }
      this.headerOptions[this.widget[':widget']].props.footerOptions =
        footerOptions;
      this.headerOptions[this.widget[':widget']].props.showLastUpdatedDate =
        showLastUpdatedDate;
    },
    handleSelectionChange() {},
    rowSelections() {},
    metadataUpdated(data) {
      this.$emit('metadataUpdated', data);
    }
  }
};
</script>
<style lang="css">
.entity-details-table-wrapper .dashboard-service-table-wrapper {
  margin: 0;
  padding-bottom: 0;
}
.entity-details-table-wrapper .inputClassForEntityTaggingSearch {
  height: 100%;
}
</style>
